@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/fonts-2024';
@import 'styles/colors-2023';

.tonal_modal {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 16px;

	> p {
		margin: 0 0 10px 0;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}

	.ccc {
		text-decoration: underline;
		display: inline-block;
		margin-left: 5px;

		button {
			@include body5;

			color: #999;
			margin-top: 0;
			margin-bottom: 0;

			@include xs-only {
				font-weight: 500;
			}

			> picture {
				top: -2px;
				left: 1px;
			}
		}
	}
}

.trigger {
	text-decoration: underline;
	background: none;
	border: none;
	font-size: 18px;
	font-weight: bold;
	display: inherit;
	cursor: pointer;
	padding: 1px 0.4ch;
	color: black;
}

.tooltip_icon {
	position: relative;
	left: 5px;
	vertical-align: middle;
	display: inline-flex;

	filter: saturate(0);
	opacity: 0.7;

	img {
		width: 14px;
		height: 14px;
	}
}

.content {
	background-color: #fff;
	max-width: calc(100vw - 40px);
	width: 800px;
	padding: 60px;
	position: relative;

	z-index: 1000;
	@include xs-only {
		padding: 20px;
		padding-top: 60px;
	}

	display: flex;
	flex-direction: column;

	border-radius: 5px;

	transition: all 0.4s ease;
}

.modal_h {
	@include sm-down {
		@include h4-mobile;
	}

	@include h3-desktop;
	margin-bottom: 20px;
}

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	margin: 0 20px;
	line-height: 1.4;
	box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

.close_button {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 10000;
	cursor: pointer;
	border: none;
	background-color: rgb(255 255 255 / 75%);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 28px;
	}

	@include xs-only {
		top: 15px;
		right: 15px;
	}
}

.table {
	text-align: left;
	font-size: 12px;

	> table {
		width: 100%;
		table-layout: fixed;
	}

	td,
	th {
		padding: 15px;

		@include xs-only {
			&:first-child {
				padding-left: 0;
			}
			padding: 10px 5px;
		}
	}

	th {
		font-size: 1.4em;
		font-weight: 700;
	}

	td {
		color: #797c8e;
		word-wrap: break-word;
	}

	.t_left {
		color: #000;
	}

	img {
		max-width: 20px;
		margin: 0 auto;
	}
}

.size_guide_header {
	@include subheading1;
	text-align: center;

	margin: auto auto 4rem;
}

.size_table_container {
	ul {
		list-style: none;
		justify-content: center;
		display: flex;
		gap: 4rem;
		align-items: center;
		flex-wrap: wrap;
	}

	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		> p {
			@include body6;
			padding: 0.5rem 1rem;
			border-radius: 5px;
			background-color: $neutral-8;
			margin-bottom: 2rem;
		}

		> div {
			transform: translateX(-0.75rem);
		}
	}

	@include xs-only {
		ul {
			gap: 2rem;
		}

		li {
			> p {
				@include body7;
				margin-bottom: 2rem;
			}
		}
	}
}
