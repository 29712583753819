.container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	input {
		&:focus-visible {
			+ label {
				outline: 5px auto Highlight;
				outline: 5px auto -webkit-focus-ring-color;
			}
		}
	}
}

.button {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	display: flex;
	color: white;
	cursor: pointer;

	justify-content: center;
	align-items: center;

	> svg {
		height: 100%;
		width: 22px;
		margin-right: 10px;
	}

	> span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.chevron {
	transform: rotate(180deg);
}

.dropdown {
	position: absolute;

	background: white;

	top: 35px;
	left: 0;

	&[data-flip='true'] {
		top: auto;
		left: auto;
		right: 0;
		bottom: 35px;
	}

	&[data-flip-horizontal='true'] {
		left: auto;
		right: 0;
	}

	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 25%) 0 4px 10px;
}

.countries_list {
	list-style: none;
	padding: 15px;
	margin: 0;
}

.country {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	cursor: pointer;

	svg {
		width: 21px;
		height: 15px;
	}

	p {
		margin-left: 10px;
		margin-bottom: 0;
		font-size: 12px;
		color: black;
		line-height: 200%;
		white-space: nowrap;

		span {
			opacity: 0.7;
		}
	}
}
