@import 'styles/constants.scss';

.tooltipButton {
	position: absolute;
	display: inline-block;

	right: 10px;
	top: 10px;

	width: 40px;
	height: 40px;

	max-width: 18px;
	max-height: 18px;

	background-image: url('https://eightsleep.imgix.net/tooltip_fc93aa4a-eefe-4df4-bfd4-ea0b6aeab035.svg?v=1637769432');
	background-color: transparent;
	background-size: contain;
	background-repeat: no-repeat;

	font-size: 12px;
	font-weight: 600;
	border: none;
	border-radius: 50%;
}

.tooltipContentMain {
	padding: 20px 16px;
	text-align: initial;
	max-width: 500px;

	cursor: auto;
}

.promoText {
	font-weight: 500;
	// color: $brand-primary;

	margin-bottom: 2px;

	img {
		max-width: 16px;
		position: relative;
		top: 3px;
		margin-right: 2px;
	}
}

.tooltipBadge {
	margin-bottom: 10px;
}
