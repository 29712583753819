@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}

.pricing {
	border-bottom: #d9d9d9 solid 1px;
}

.total_price {
	@include headline6;
	font-size: 2.25rem; // TODO not in spec

	margin-bottom: 0.25rem;

	@include sm-down {
		@include headline8;

		margin-bottom: 0.25rem;
	}

	del {
		color: #999;
	}
}

.alternate_pricing {
	@include tag1;

	color: #a0a0a0;
	margin-top: 5px;

	svg {
		width: auto;
		height: 1em;
		margin: 0 0.5ch;
	}

	&:last-child {
		margin-bottom: 1.5rem;
	}
}

.order_summary {
	margin-bottom: 3rem;
	margin-top: 1rem;

	p {
		@include headline10;
		color: #999;
		margin-bottom: 0.5rem;
	}

	strong {
		@include headline8;
	}
}

.button_container {
	button {
		width: 100%;
		display: grid !important;
	}

	margin-bottom: 0.75rem;
}

.shipping_and_benefits {
	border-radius: 0.5rem;
	border: 1px solid rgba(0, 0, 0, 0.2);
	padding: 2rem 2.5rem;
	margin-bottom: 35px;
}

.shipping_text {
	@include headline10;

	margin-bottom: 1rem;
}

.benefits {
	list-style: none;

	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	margin-bottom: 0;

	@include sm-down {
		gap: 0.5rem;
	}
}

.benefit {
	display: flex;
	gap: 0.5rem;

	align-items: center;

	p {
		@include tag1;
	}
}
