@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	padding: 0;
	@include md-up {
		padding-right: 5rem;
	}

	position: relative;
}

.desktop_carousel_wrapper {
	@include sm-down {
		display: none;
	}
}

.enhanced_plan_wrapper {
	picture {
		aspect-ratio: 853 / 580;

		> img {
			object-fit: contain !important;
			border-radius: 15px;
			background-color: #f0f0f0;

			@include sm-down {
				border-radius: 7px;
			}
		}
	}
}

.container_inner {
	display: grid;
	justify-content: space-between;
	grid-template-columns: 50% 47%;
	@include min(1300px) {
		grid-template-columns: 60% 35%;
	}

	margin-inline: auto;

	@include md-up {
		grid-template-columns: 58fr 33fr;
		gap: 2.5rem;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		row-gap: 0;
	}
}

.sections {
	display: flex;
	flex-direction: column;

	@include md-up {
		padding-top: 1.25rem;
	}

	@include sm-down {
		section {
			padding: 0 1.5rem;
		}
	}

	@include xs-only {
		section {
			padding: 0 1.25rem;
		}
	}
}

.mobile_sections {
	display: none;

	@include sm-down {
		display: flex;
		flex-direction: column;
	}
}

.first_section {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.side_nav_outer {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;

	z-index: 5;

	pointer-events: none;

	@include sm-down {
		display: none;
	}
}

.side_nav_container {
	position: sticky;
	top: 0;
	margin-right: 1rem;
	height: 100svh;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	* {
		pointer-events: auto;
	}
}

.mobile_section {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.sticky_wrapper_desktop {
	background: $white;
	position: sticky;

	z-index: 10;
	top: calc($promo-height + 70px + var(--promo-bar-translate, 0px));

	@include sm-down {
		display: none;
	}

	margin-bottom: $spacing-medium-desktop;

	transition: top $promo-transition-time $promo-transition-ease;
}

.bottom_sticky_wrapper {
	display: flex;
	padding: 0.75rem 2.5rem;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	border-top: 1px solid #121212;
	border-bottom: 1px solid #121212;
	background: #121212;
	z-index: 10;
	bottom: 0;

	margin-left: -2.5rem;
	margin-right: -5rem;

	position: sticky;

	@include sm-down {
		position: fixed;
		right: 0;
		left: 0;
		margin: 0;
		padding: 1rem 1.25rem;
	}
}

.pricing_button {
	.pricing_content {
		@include headline6;
		color: white;
		font-size: 1.5rem; // TODO out of spec
	}

	del {
		font-size: 0.75em;
		color: #d3d3d3;
		transform: translateY(0.075em);
	}

	@include xs-only {
		button {
			font-size: 1.25rem;
		}
	}

	> span {
		display: inline-flex;

		gap: 0.75rem;
	}

	&:hover,
	&:focus-visible {
		.big_chevron {
			background-color: #d3d3d3;
		}
	}
}

.pricing_button_interior {
	display: inline-flex;
	align-items: flex-start;
	gap: 0.25ch;
	flex-direction: column;
	justify-content: center;

	svg {
		width: 0.75em;
		height: 0.75em;
		margin-left: 0.25ch;

		@include md-up {
			display: none;
		}
	}
}

.big_chevron {
	border-radius: $br-circle;
	width: 1.5rem;
	height: 1.5rem;
	padding: 0.125rem;
	margin-top: 0.25rem;

	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid white;

	transition: all 0.15s ease-out;

	@include sm-down {
		display: none;
	}

	svg {
		transform: translateY(0.0625rem);
	}
}

.membership_content {
	display: block;
	color: white;
}

.shipping_text {
	@include body6;
	color: white;
	text-align: center;
	margin-top: 0.5rem;
}

.membership_text {
	@include body6;
	color: white;
}

.checkout_button {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
