@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	overflow-y: auto;
	background-color: $white;
	color: $black;

	padding: 1.75rem !important;

	max-width: 40rem;

	margin: auto;
	@include sm-down {
		padding: 4rem 1.75rem 1.25rem !important;
	}
}

.tabs {
	display: flex;
	list-style: none;
	margin: 0;
	gap: 1rem;

	align-items: center;
	justify-content: space-around;

	text-align: center;

	border-bottom: 1px solid #a0a0a0;

	position: relative;

	//contain: paint;

	&::after {
		content: '';
		position: absolute;
		bottom: -1px;
		left: calc(var(--selected-tab) * calc(100% / var(--num-tabs)));
		width: calc(100% / var(--num-tabs));
		height: 2px;
		background-color: $black;

		transform: translateY(0.5px);

		transition: all 0.25s ease-out;
	}
}

.tabs li {
	flex: 1;
	button {
		padding: 1rem 0;

		font-weight: 400;
		font-size: 0.875rem; // TODO out of spec

		width: 100%;

		@include xs-only {
			font-size: 0.75rem;
		}
	}

	opacity: 0.5;

	&:hover {
		opacity: 0.9;
	}

	&[data-selected='true'] {
		opacity: 1;

		button {
			font-weight: 500;
		}
	}

	&[data-option='affirm'] {
		button > span {
			transform: translateY(-10%);
		}
	}
}

.affirm_text {
	height: 1.25em;
	transform: translateY(0.04em);
	width: auto;
}

.klarna_text {
	height: 0.8em;
	width: auto;
}

.tab_content {
	display: grid;
	grid-template-areas: 'center';

	padding-top: 1.25rem;

	> * {
		grid-area: center;
	}

	section {
		opacity: 0;
		visibility: hidden;
		transition: all 0.25s ease-out;

		display: flex;
		flex-direction: column;

		&[data-selected='true'] {
			opacity: 1;
			visibility: visible;
		}
	}
}

.pricing_table,
.total_table {
	width: 100%;

	border-collapse: collapse;

	td,
	th {
		padding: 0;

		&:last-child {
			text-align: right;
		}
	}
}

.pricing_table {
	td {
		color: #636363;
		@include headline10;
		line-height: 1.6;
	}
}

.pricing_table em {
	@include headline10;
	text-transform: uppercase;
	color: #ff5b00;

	display: inline-flex;
	padding: 0.3125rem 0.625rem;
	justify-content: center;
	align-items: center;

	border-radius: 0.25rem;
	background: rgba(255, 91, 0, 0.1);

	margin-right: 1rem;
}

.pricing_table del {
	color: rgba(153, 153, 153, 0.6);

	text-decoration: none;
	span {
		text-decoration: line-through;
	}

	margin-right: 0.5ch;
}

.subscription_info {
	color: rgba(153, 153, 153, 0.6);
	margin-right: 0.5ch;

	@include xs-only {
		display: none;
	}
}

.total_spacer {
	flex: 1;

	border-bottom: 1px solid #f2f4f6;

	margin: 1.25rem 0;
}

.spacer {
	height: 1px;
	width: 100%;
	background: #f2f4f6;

	margin: 1.25rem 0;
}

.total {
	@include headline7;

	small {
		@include body5;
		margin-left: 0.5ch;
	}
}

.financing_extra_item {
	@include headline10;

	line-height: 2.15;

	color: #636363;
}

.disclaimer {
	@include body5;
	color: #8e8e8e;
	margin-bottom: 1.25rem;
}

.tooltip_container {
	position: relative;
	display: inline-flex;
	align-items: center;

	transform: translateY(0.2rem);

	margin-left: 0.25rem;
}

.tooltip {
	z-index: 9999999 !important;
	background: #f1f2f4 !important;
	padding: 0.5rem 0.75rem;

	width: 17rem !important;
}

.tooltip_content {
	@include body5;
	color: #636363;
}

.tooltip_button {
	background: none;
	margin: 0;
	padding: 0;
	border: none;

	cursor: pointer;
}

.mobile_hide {
	@include xs-only {
		display: none;
	}
}
