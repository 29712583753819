@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.inputGroup {
	display: flex;
	width: 100%;
}

.inputGroup label {
	display: none;
}

.inputGroup input {
	padding: 1.25rem;
	border-radius: $br-sm;
	background: #121212;

	font-size: 1rem;
	color: $text-white;

	width: 100%;
}

.inputGroup input::placeholder {
	color: #636363;
}
