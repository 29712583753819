@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.footer {
	background: black;
	color: white;

	h3 {
		font-size: 1.25rem;
		line-height: 120%;
		margin: 0 0 1rem;
	}

	padding: 3.75rem 1.875rem;

	@include md-up {
		padding: 5.625rem 4.6875rem;
	}
	position: relative;
}

.country_picker_container {
	position: absolute;
	top: 0;
	right: 0;

	padding: 3.75rem 1.875rem;

	z-index: 5;

	@include md-up {
		padding: 5.625rem 4.6875rem;
	}
}

.logo {
	width: 2.25rem;
	margin-bottom: 3.75rem;

	@include sm-down {
		margin-bottom: 2rem;
	}
}

.inner {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@include md-up {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 3rem;
		justify-content: space-between;
		align-items: start;
		justify-items: end;

		margin-bottom: 7rem;
	}
}

.input_container {
	max-width: 25rem;

	@include md-up {
		max-width: 17rem;
	}
}

.email_header {
	span {
		display: inline-block;
		margin-top: 0.25rem;
		margin-bottom: 1rem;
		font-weight: normal;
		font-size: 1rem;
		line-height: 140%;
	}
}

.disclaimer {
	font-size: 0.75rem;
	line-height: 170%;
	color: #8e8d92;
	margin: 1rem 0 0;
}

.footer_list {
	list-style: none;
	width: 100%;
	margin: 3.75rem 0;
	padding: 0;

	@include md-up {
		display: flex;
		margin: 0;
		max-width: 54rem;
		justify-content: space-between;
	}
}

.sublist {
	list-style: none;
	max-height: var(--height);
	transition: all 0.25s ease;
	overflow: hidden;
	margin: 0;
	padding: 0;
	visibility: hidden;

	@include md-up {
		max-height: unset;
		visibility: visible;
	}
}

.sublist_expanded {
	visibility: visible;
}

.list_item {
	position: relative;
	padding: 1.875rem 0;
	border-top: rgb(255 255 255 / 15%) 1px solid;

	&:last-child {
		border-bottom: rgb(255 255 255 / 15%) 1px solid;
	}

	h3 {
		margin: 0;
	}

	@include md-up {
		border: none;

		&:last-child {
			border: none;
		}

		min-width: 9rem;
		padding: 0;

		.plus_minus_toggle {
			display: none;

			button {
				background: none;
				border: none;
				padding: 0;
				margin: 0;
			}
		}
	}
}

.expand_controls {
	@include md-up {
		display: none; // For accessibility, don't show the header as a input label on desktop
	}

	input {
		&:focus-visible {
			+ .checkbox_label {
				outline: white thin solid;
				outline-offset: 0.5rem;
			}
		}
	}
}

.desktop_header {
	display: none;
	@include md-up {
		display: unset;
	}
}

.checkbox_label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.sublist_item {
	margin-top: 1rem;

	a {
		font-size: 1rem;
		line-height: 140%;
		color: #8e8d92;
		margin: 0;
	}
}

.call_us {
	margin-bottom: 1rem;
}

.asterisk {
	@include body6;
	margin: 2rem 0 0;
	color: #8e8d92;

	span {
		display: block;
	}
}

.contact_links {
	a {
		display: block;
		color: white;
		font-size: 1rem;
		line-height: 140%;
	}

	display: flex;
	flex-direction: column;
	gap: 1rem;

	@include md-up {
		flex-direction: row;
	}
}

.social_links {
	justify-self: end;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		display: inline-grid;
		grid-template-columns: repeat(3, 2rem);
		gap: 1rem;
	}
}

.plus_minus_toggle {
	width: 1.5rem;
	height: 1.5rem;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-areas: 'container';
	position: relative;
}

.line {
	width: 1rem;
	height: 0.1rem;
	border-radius: 9999px;
	grid-area: container;
	background: white;

	transition: transform 0.25s ease;
}

.contact_container {
	display: grid;
	grid-template-columns: 1fr 1fr;

	padding-bottom: 1.5rem;
	border-bottom: 1px solid #363636;
}

.copyright_and_country {
	padding-top: 1.5rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;

	justify-content: space-between;

	> div {
		justify-self: end;
	}
}

.copyright {
	@include body4;
	color: #d3d3d3;
}
