@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}

.header {
	padding: 0 12px;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

.description {
	color: $text-grey-dark;
}

.badge {
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;

	color: #7d7d7d;

	svg {
		height: 1.1em;
	}
}

.benefits {
	padding: 16px 12px;
}

.benefits_list {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	li {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}
}

.disclaimer {
	color: #a6a6a6;
}

.faqs .disclaimer {
	padding: 16px 32px;

	@include xs-only {
		padding: 16px 12px;
	}
}

.faqs {
	@include xs-only {
		padding: 0 12px;
	}
}

.faqs_list {
	list-style: none;
	border-radius: $br-sm;
	border: 2px solid $neutral-grey-2;
	padding: 32px;

	margin: 0;

	list-style: none;

	display: flex;
	flex-direction: column;
	gap: 1rem;

	@include xs-only {
		padding: 16px;
	}
}

.answer {
	margin-top: 0 !important;
	color: #a6a6a6;
}
