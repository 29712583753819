@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	color: $black;

	border-radius: 0.5rem;
	box-shadow: #d3d3d3 0 0 0 1px inset;

	transition: all 0.2s ease-out;
	position: relative;

	height: 100%;

	&:hover,
	&:focus-visible {
		background-color: rgba(230, 235, 255, 0.75);
	}
}

.standard_card {
	padding: 1.5rem 0.75rem;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&.v_align {
		height: 100%;
	}
}

.standard_titles {
	text-align: center;
}

.standard_titles .description {
	color: $text-grey-dark;

	max-width: 22ch;
	margin-inline: auto;
}

.checklist {
	list-style: none;
	margin: 0;

	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	li {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 0.25rem;
		align-items: start;

		color: $text-grey-dark;

		> p {
			word-wrap: break-word;
			word-break: break-word;
			hyphens: auto;
			-webkit-hyphens: auto;
			overflow-wrap: anywhere;
			-webkit-line-break: after-white-space;
		}
	}
}

.enhanced_plan_wrapper {
	text-align: center;

	color: $text-grey-dark;

	margin-top: 1rem;
}

.enhanced_plan_banner {
	background: rgba(36, 106, 255, 0.06);
	color: rgba(24, 98, 255, 1);
	padding: 16px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;

	> span {
		text-align: center;
		max-width: 100px;
	}

	margin-top: 1rem;
}

.badge {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
}

.checklist_wrapper {
	margin-top: 1rem;
}

.checklist_title {
	text-align: left;
	color: $text-grey-dark;

	& + .checklist {
		margin-top: 0.625rem;
	}
}
