@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;

	display: grid;
	grid-template-columns: 1fr;

	width: 2rem;
	max-height: 20rem;

	gap: 0.25rem;
}

.slice {
	min-height: 3rem;
}

.button {
	display: grid !important;
	width: 100%;
	height: 100%;

	&:hover,
	&:focus-visible {
		.button_interior {
			scale: 1.1;
		}
	}

	&:active {
		.button_interior {
			scale: 0.95;
		}
	}

	> span {
		display: flex;

		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		padding: 0.25rem 0;
	}
}

.button_interior {
	display: block;
	background: black;
	width: 2px;
	height: 100%;

	transition: all 0.1s ease-out;
}
