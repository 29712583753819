@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts';
@import 'styles/mixins/theme';
@import 'styles/colors';

.country_picker_mobile {
	max-width: 300px;
	color: white;

	* {
		transition: all ease 0.25s;
	}

	.top_line {
		position: relative;
		z-index: 2;
		background-color: $ui-white;
	}
}

.flag {
	width: 29px;
	height: 14px;
	margin: 0 3px;
}

.dropdown_button {
	color: white !important;
	background: black !important;
	padding: 0;

	height: 22px;

	* {
		cursor: pointer;
	}

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.dropdown_arrow {
	height: 14px;
	width: 14px;
	transform: rotate(-90deg);
	filter: invert(1);
}

.dropdown_arrow_open {
	transform: rotate(-270deg);
}

.drawer {
	flex-direction: column;
	display: flex;
	margin-left: 32px;

	max-height: 0;
	visibility: hidden;
	overflow: hidden;

	button {
		background: none;
		border: none;

		* {
			cursor: pointer;
		}

		display: flex;
		align-items: center;
		width: 100%;
		margin-top: 10px;
	}
}

.drawer_open {
	max-height: 300px;
	visibility: visible;
}

.country_button {
	display: flex;
	color: white !important;
	cursor: pointer;

	justify-content: center;
	align-items: center;

	> svg {
		height: 100%;
		width: 22px;
		margin-right: 10px;
	}

	> p {
		font-size: 16px;
		line-height: 140%;
		margin-bottom: 0;

		span {
			opacity: 0.7;
		}
	}
}
