@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}

.normal_span {
	display: block; // For line breaks
}

.number_list {
	list-style-position: inside;
}
