@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.thumbnails_container {
	--thumbnail-width: 5.3rem;
	--thumbnail-height: 3rem;
	--thumbnails-gap: 0.5rem;
	position: absolute;
	bottom: 1rem;
	left: 0;
	right: 0;
	width: 100%;
	max-width: calc(var(--visible-thumbnails) * (var(--thumbnail-width) + var(--thumbnails-gap)));
	overflow: hidden;
	margin: 0 auto;
}

.thumbnails {
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	gap: var(--thumbnails-gap);

	&[data-use-dots='true'] {
		justify-content: center;
		transform: none !important;

		.thumbnail_image,
		.thumbnail_video_container {
			display: none !important;
			visibility: hidden;
		}

		gap: 0;
		isolation: isolate; /* Create a new stacking context */
		mix-blend-mode: difference;

		.thumbnail_button {
			padding: 0.25rem !important;
		}

		.thumbnail {
			width: 1rem;
			height: 1rem;
			opacity: 0.25;
			flex: 0 0 1rem;

			&[data-highlight='true'] {
				opacity: 1;
			}
		}
	}

	&[data-use-dots='false'] {
		.thumbnail_dot {
			@include md-up {
				display: none;
			}
		}

		@include sm-down {
			justify-content: center;
			gap: 0;
			isolation: isolate; /* Create a new stacking context */
			mix-blend-mode: difference;
			transform: none !important;
		}
	}
}

.thumbnail {
	display: block;
	border-radius: 0.5rem;
	contain: paint;
	position: relative;
	opacity: 0.5;
	width: var(--thumbnail-width);
	flex: 0 0 var(--thumbnail-width);
	height: var(--thumbnail-height);

	&[data-highlight='true'] {
		opacity: 1;
	}

	@include sm-down {
		--thumbnail-width: 1rem;
		width: var(--thumbnail-width);
		height: var(--thumbnail-width);
	}

	transition: opacity 0.25s ease-in-out;
}

.thumbnail_button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	span,
	picture,
	img {
		width: 100%;
		height: 100%;
		display: block;
	}

	@include sm-down {
		padding: 0.25rem !important;
	}
}

.thumbnail_video_container {
	height: 100%;
	width: 100%;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;

		z-index: 1;
		transform: translate(-50%, -50%);
	}
}

.thumbnail_dot {
	background: white;
	opacity: 1;
	border-radius: $br-circle;
	height: 100%;
}

.thumbnail_image,
.thumbnail_video_container {
	filter: brightness(1.05);

	background: #f2f2f2;
	@include sm-down {
		display: none !important;
		visibility: hidden;
	}
}
