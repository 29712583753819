@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;
	background-color: #f2f2f2;

	position: relative;
	border-radius: 1rem;

	height: 100%;
	width: 100%;

	overflow: hidden;
}

.slider_wrapper {
	overflow: hidden;
	border-radius: 1rem;

	width: 100%;
	height: 100%;
}

.slider {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	margin: 0;
	list-style: none;
	position: relative;

	display: grid;

	grid-template-areas: 'center';

	> * {
		grid-area: center;
	}
}

.slider li {
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	flex: 0 0 100%;

	opacity: 0;
	transition: all 0.25s ease-out;

	background: #f2f2f2;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: top;
	}
}

.controls {
	position: absolute;

	@include sm-down {
		display: none;
	}

	width: 90%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	justify-content: space-between;
	pointer-events: none;
	button {
		padding: 1rem !important;
		background: $white !important;

		line-height: 0;
		border-radius: $br-circle;
		pointer-events: auto;
		filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.14)) drop-shadow(0 11px 11px rgba(0, 0, 0, 0.12)) drop-shadow(0 25px 15px rgba(0, 0, 0, 0.07)) drop-shadow(0 45px 18px rgba(0, 0, 0, 0.02))
			drop-shadow(0 70px 20px rgba(0, 0, 0, 0));

		&:hover,
		&:focus-visible {
			filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15)) drop-shadow(0 15px 15px rgba(0, 0, 0, 0.13)) drop-shadow(0 34px 20px rgba(0, 0, 0, 0.08)) drop-shadow(0 60px 24px rgba(0, 0, 0, 0.02))
				drop-shadow(0 94px 26px rgba(0, 0, 0, 0));
			scale: 1.02;
		}

		&:active {
			filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.13)) drop-shadow(0 5px 3px rgba(0, 0, 0, 0.08)) drop-shadow(0 9px 3px rgba(0, 0, 0, 0.02))
				drop-shadow(0 13px 4px rgba(0, 0, 0, 0));
			scale: 0.95;
		}
	}
}

.control_prev {
	transform: translateX(-50%);
}

.control_next {
	transform: translateX(50%);
}

.carousel_photo {
	display: block;
	width: 100%;
	height: 100%;

	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.carousel_video {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.dots {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 0.5rem;
}

.dot.dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-out;
	font-size: 0;
	color: transparent;
}

.dot_selected.dot_selected {
	background-color: rgba(0, 0, 0, 0.5);
}

.hide {
	visibility: hidden;
}
