@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $white;
	color: $black;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	position: relative;
	padding: $spacing-xl $spacing-sm;
	@include sm-down {
		padding: $spacing-lg 1rem $spacing-xl;
	}

	header {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;

		.new_tag {
			@include blue_tag;
			width: fit-content;
			@include sm-down {
				font-size: 1rem;
			}
		}

		h2 {
			@include headline3;

			max-width: 17ch;
			margin-inline: auto;
			@include sm-down {
				@include headline4;
			}
		}
		.video_button {
			display: flex;
			align-items: center;
			@include headline7;
			color: #1a3ebb;
			gap: 0.25rem;
		}
	}
}
.image_container {
	margin-bottom: 2rem;
	img {
		max-width: 1200px;
	}
	@include sm-down {
		margin-top: 2rem;
	}
}

.modal_button {
	background-color: #f2f2f2 !important;
	color: $black !important;
	width: fit-content;

	span {
		gap: 0.5rem;
	}
}
.plus {
	margin-left: 0.25rem;
}

.closeButton {
	position: absolute;
	top: 0%;
	right: 0%;
	display: flex;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8px);
	padding: 0.5rem;
	border-radius: 0.5rem;
	margin: 1rem;
	width: fit-content;
	cursor: pointer;
}

.fullScreen {
	inset: 0;
	position: fixed;
	z-index: 1300;

	background: rgba(0, 0, 0, 0.2);

	backdrop-filter: blur(18px);

	transition: all 0.25s ease-out;

	max-width: unset !important;

	display: flex;
	justify-content: center;
	align-items: center;
}

.full_screen_inner {
	background-color: rgba(255, 255, 255, 0.9);

	position: absolute;

	border-radius: 1rem;
	overflow: hidden;

	inset: 2rem;

	margin: auto;

	max-height: calc(100% - 4rem);
	max-width: 100%;

	transition: all 0.25s ease-out;

	@include xs-only {
		inset: 0;
		max-height: 100%;
		border-radius: 0;

		img {
			max-height: 360px;
		}
	}
}
.full_screen_inner_scrollable {
	overflow: auto;
}

.fullVideo {
	video {
		height: 100% !important;
		@include sm-down {
			width: auto !important;
		}
	}
}

.videoContainer {
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.heroImage {
	margin: auto;
	@include xs-only {
		margin: 0px;
		margin-bottom: 2rem;
	}
	max-width: 915px;

	display: block;
	width: 100%;
}
