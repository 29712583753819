@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	padding: 24px;
	border-radius: $br-sm;
	border: 1px solid #1a3ebb;
	background: rgba(36, 106, 255, 0.06);
}

.container header {
	p {
		color: $neutral-grey-3;
	}
	margin-block-end: 1rem;
}

.checks {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 2px;

	p {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	svg {
		height: 1em;
		width: 1em;
	}
}
