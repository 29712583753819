@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/mixins/theme';
@import 'styles/mixins/pod-3-fonts';

.prequalify {
	color: white;
	text-decoration: none !important;
	cursor: pointer;
	margin-top: 26px;
	font-weight: 700;
	font-size: 16px;
}

$text-arrow-space: 10px;
$shaft-width: 14px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.the_arrow {
	width: $shaft-width;
	transition: all 0.2s;

	&.pos_left {
		position: absolute;
		top: 50%;
		left: 0;

		> .shaft {
			width: 0;
			background-color: currentColor;

			&:before,
			&:after {
				width: 0;
				background-color: currentColor;
			}

			&:before {
				transform: rotate(0);
			}

			&:after {
				transform: rotate(0);
			}
		}
	}

	&.pos_right {
		> .shaft {
			width: $shaft-width;
			transition-delay: 0.2s;

			&:before,
			&:after {
				width: $arrow-head-width;
				transition-delay: 0.3s;
				transition: all 0.5s;
			}

			&:before {
				transform: rotate(40deg);
			}

			&:after {
				transform: rotate(-40deg);
			}
		}
	}

	> .shaft {
		background-color: currentColor;
		display: block;
		height: $shaft-thickness;
		position: relative;
		transition: all 0.2s;
		transition-delay: 0;
		will-change: transform;

		&:before,
		&:after {
			background-color: currentColor;
			content: '';
			display: block;
			height: $arrow-head-thickness;
			position: absolute;
			top: 0;
			right: 0;
			transition: all 0.2s;
			transition-delay: 0;
		}

		&:before {
			transform-origin: top right;
		}

		&:after {
			transform-origin: bottom right;
		}
	}
}

.animated_arrow {
	align-self: center;
	display: inline-block;
	text-decoration: none;
	position: relative;
	transition: all 0.2s;

	&:hover {
		text-decoration: none;

		> .the_arrow.pos_left {
			> .shaft {
				width: $shaft-width;
				transition-delay: 0.1s;
				background-color: currentColor;

				&:before,
				&:after {
					width: $arrow-head-width;
					transition-delay: 0.1s;
					background-color: currentColor;
				}

				&:before {
					transform: rotate(40deg);
				}

				&:after {
					transform: rotate(-40deg);
				}
			}
		}

		> .main {
			transform: translateX($shaft-width + $text-arrow-space);

			> .the_arrow.pos_right {
				> .shaft {
					width: 0;
					transform: translateX(200%);
					transition-delay: 0;

					&:before,
					&:after {
						width: 0;
						transition-delay: 0;
						transition: all 0.1s;
					}

					&:before {
						transform: rotate(0);
					}

					&:after {
						transform: rotate(0);
					}
				}
			}
		}
	}

	> .main {
		display: flex;
		align-items: center;
		transition: all 0.2s;

		> .text {
			margin: 0 $text-arrow-space 0 0;
			line-height: 1;
		}

		> .the_arrow {
			position: relative;
		}
	}
}

.affirm_link {
	background: none;
	border: none;
	outline: none;
	color: #999;
	font-size: 12.5px;
	text-decoration: none;
	font-weight: 400;
	display: inline;
	flex: 0 0 auto !important;
	padding: 0;
	cursor: pointer;
	font-family: inherit;

	> span {
		display: inline !important;
	}

	> svg:global(.affirmTransform) {
		height: 1.3em;
		max-height: 1.3em;
		transform: none;
		cursor: pointer;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.klarna_link {
	svg {
		width: 4em;
	}
}
