@import 'styles/colors';

.button_wrapper {
	border: none;
	background: none;
	padding: 0;
	position: relative;
	font: inherit;
	color: inherit;

	cursor: pointer;
}

.tooltip_container {
	position: absolute;
	background: $ui-white;
	filter: drop-shadow(0 0 5px black);
}

.tooltip_open {
	visibility: visible;
}

.arrow_up {
	background: $ui-white;
	position: absolute;
	bottom: -6px;
	left: 0;
	width: 12px;
	height: 12px;
	z-index: -1;
}
