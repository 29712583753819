@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: fixed;
	z-index: $z_index_header + 1;
	top: 0;
	right: 0;
	left: 0;

	background-color: black;

	display: grid;
	align-items: center;

	color: $text-black;
}

.logo {
	margin-inline: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	height: $nav-height-desktop;

	@include sm-only {
		height: $nav-height-mobile;
	}
}

.promo_bar {
	position: relative;
	background: $brand-royal-140;
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--clean-nav-promo-height, $promo-height);
	padding: 0;
	font-weight: 500;
	z-index: 1;
	column-gap: 0;
	flex-direction: column;
	overflow: hidden;

	@include body5;

	a,
	p {
		color: $text-black;
	}

	&:not(.shown) {
		height: 0;
		visibility: hidden;
	}

	transition-property: height, visibility;
	transition-duration: $promo-transition-time;
	transition-timing-function: $promo-transition-ease;
}

.close_button {
	position: absolute;
	right: 1rem;
	background: none;
	padding: 0;
	margin: 0;
	outline: 0;
	border: none;
	cursor: pointer;
	width: 1rem;
	height: 1rem;

	@include sm-down {
		left: 1rem;
	}
}
