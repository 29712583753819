@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	position: relative;
	z-index: 2;

	@include section-padding;
}

.title {
	@include headline3;

	@include sm-down {
		font-size: 2.5rem;
	}
}

.inner {
	max-width: 1030px;
	margin-inline: auto;
}

.faqs {
	padding-top: $spacing-md;

	@include xs-only {
		padding-top: $spacing-sm;
	}
}

.question_container {
	padding: 24px 0;
	border-bottom: 1px solid #dddcdb;

	@include xs-only {
		padding: 16px 0;
	}

	&:first-child {
		border-top: 1px solid #dddcdb;
	}

	img:global(.inverted) {
		right: 0;
		bottom: auto;
		top: 50%;
		translate: 0 -50%;
	}

	.question {
		@include headline7;
		@include xs-only {
			@include headline10;
		}
		margin-top: 16px;
		display: block;
		line-height: 1.4;
	}

	.answer {
		font-size: 22px;
		margin-top: 16px;
		display: block;
		line-height: 1.4;

		color: #717171;

		@include xs-only {
			font-size: 14px;
		}
	}

	.question {
		margin: 0;
		max-width: 90%;
	}
}
