@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.ecap_content_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 50%;
	padding: 4rem;
	z-index: 1;

	@include sm-down {
		gap: 50px;
		width: 100%;
		padding: 2.5rem $spacing-medium-mobile;
		align-self: flex-start;
		height: 100%;
		justify-content: flex-start;
	}
}

.quiz {
	display: flex;
	row-gap: 10px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 40px;

	@include sm-down {
		margin-top: 30px;
		margin-bottom: 25px;
	}
}

.quiz_button {
	padding: 15px 24px;
	border-radius: 8px;
	background: $white;
	width: 100%;
	display: flex;
	text-align: center;
	font-size: 18px;

	@include sm-down {
		font-size: 14px;
		padding: 14px 24px;
	}
}

.quiz_question {
	color: $text-grey-dark;
	max-width: 35ch;
	margin: 1.5rem auto 0;
}

.eyebrow {
	color: $text-white;
	margin-bottom: 10px;
}

.headline {
	color: $text-white;
	margin-bottom: 10px;
}

.break_word {
	@include xs-only {
		display: block;
	}
}

.headchapter {
	color: $text-grey-light;
}

.dismiss {
	color: $text-grey-dark !important;
	font-weight: 500;
	text-decoration: underline;
	font-size: 1rem;
}

.complete_screen_container {
	gap: 60px;
	max-height: 800px;
}

.question_container {
	width: 100%;
}

.screen_container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;

	@include sm-down {
		justify-content: flex-start;
	}
}

.phone_form_container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 500px;

	@include sm-down {
		justify-content: flex-start;
	}
}

.disclaimer {
	color: $text-grey-light;
	max-width: 430px;
	margin: 40px auto 0;

	@include xs-only {
		margin: 20px auto 0;
	}
}

.children_wrapper {
	max-width: 430px;
	width: 100%;
	margin: 40px auto 0;

	@include xs-only {
		margin: 20px auto 0;
	}
}

.email_form {
	margin: 10px auto 0;
	width: 100%;
}

.email_form_button {
	width: 100%;
	margin: 30px 0;
}

.email_form_input {
	color: $text-white;
	background: none;
	width: 100%;
	font-size: 16px;
	padding: 10px;
	min-height: 30px;
	border: none;
	outline: none;
	border-radius: 0;
	border-bottom: 1px solid #636363;
	box-shadow: none;

	&:focus {
		outline: none;
	}
}

.error {
	color: $error;
	font-size: 16px;
	margin: 10px 0;
	font-weight: 500;
	text-align: center;
}

.awards_container {
	display: flex;
	gap: 40px;
	align-items: center;
	justify-content: center;
	margin-top: 24px;

	@include sm-down {
		gap: 20px;
	}
}

.award_container {
	padding-right: 40px;
	border-right: 1px solid #262626;

	@include sm-down {
		padding-right: 20px;
	}
}

.award_container:last-child {
	border-right: none;
	padding-right: 0;
}

.award_logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: $white;

	> svg {
		max-width: 50px;
	}
}
