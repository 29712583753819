@import 'styles/mixins/fonts-2024';

.skeleton {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.pulse {
	animation: pulse 2s ease-in-out 0.5s infinite;
}

.wave::after {
	animation: wave 2s linear 0.5s infinite;

	background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
	content: '';
	position: absolute;

	transform: translateX(-100%);

	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@keyframes wave {
	0% {
		transform: translateX(-100%);
	}
	50% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
}

/**
 * TEST CLASSES
 */

.test_heading {
	//font-family: NeueMontreal, sans-serif;
	margin: 0;
	letter-spacing: 0;
	font-style: normal;
	color: black;

	font-weight: 400;

	font-size: 2rem;
	line-height: 1;
}

.test_image {
	object-fit: cover;
	width: 4rem;
	height: 4rem;

	border-radius: 50%;
}

.test_box {
	width: 10rem;
	height: 20rem;

	object-fit: cover;
}
