@import 'styles/mixins/media-queries';

div.container {
	color: #999;
	margin-top: 2px;

	span {
		color: #999;
		font-family: NeueMontreal, sans-serif !important;
		letter-spacing: 0;
		margin-top: 0;
		font-size: 12px;

		--height: 12px;
	}

	:global(.truemed-instructions-open) {
		color: #999;
	}

	svg {
		max-height: none !important;

		&:global(.truemed-logo) {
			transform: translateY(-1px);
		}
	}
}
