@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	background-color: $neutral-8;
	color: $neutral-black;
}

.inner {
	max-width: 89rem;
	margin-inline: auto;
	padding: 3.75rem 1.5rem;

	@include sm-up {
		padding: 3.75rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		align-items: center;
		gap: 2rem;
	}

	@include md-up {
		padding: 7.5rem;
		grid-template-columns: 621px auto;
		gap: 0;
	}
}

.text {
	margin-bottom: 2rem;
	h2 {
		@include heading5;
		margin-bottom: 0.75rem;
	}

	p {
		@include body5;
	}

	@include md-up {
		padding: 4rem;
		padding-right: 0;
		grid-column: 2;

		h2 {
			@include heading4;
			margin-bottom: 1rem;
		}

		p {
			@include body4;
		}
	}
}

.video {
	min-height: 400px;
	width: 100%;
	display: block;
	object-fit: cover;
	inset: 0;

	@include sm-up {
		grid-row: 1;
		height: 100%;
	}

	@include md-up {
		min-height: 640px;
		object-position: 20%;
	}
}

.cta {
	margin: 15px 0 0;
}
