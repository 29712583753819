.form {
	display: grid;
	grid-template-columns: 1fr 1.5rem;
	box-shadow: white 0 1px 0 0;
	padding-bottom: 0.25rem;

	transition: box-shadow 0.2s ease;

	&:focus-within {
		box-shadow: white 0 2px 0 0;

		button {
			opacity: 1;
		}
	}

	input {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		color: white;
		font-size: 1rem;

		&:focus {
			outline: none;
		}

		&:disabled {
			color: rgb(255 255 255 / 50%);
		}
	}

	button {
		background: none;
		padding: 0;
		margin: 0;
		border: none;
		cursor: pointer;
		opacity: 0.5;

		transition: opacity 0.2s ease;
	}
}

.success {
	padding-bottom: 0.25rem;
	font-size: 16px;
	color: #8e8d92;
	font-weight: 700;
}

.icon {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
}
