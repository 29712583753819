@import 'styles/mixins/theme';
@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';

.container {
	height: 100vh;
	width: 100%;
	max-width: 380px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	img {
		width: 150px;
		margin: 0 auto 25px;
	}

	h1 {
		font-size: 32px;
		color: #000;
		line-height: 1.2;
		text-align: center;
	}

	p {
		color: #000;
		text-align: center;
	}

	button {
		@include buttonlink;
	}
}
