@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	border-radius: 0.5rem;

	background-color: #f2f2f2;

	backdrop-filter: blur(12px);

	span {
		@include body5;

		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	svg {
		width: 1rem;
		height: 1rem;
	}
}
