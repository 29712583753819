@import 'styles/constants';
@import 'styles/mixins/media-queries';

.container.loaded {
	:global(.slick-slide) {
		display: flex;
	}
}

.container:not(.loaded) {
	:global(.slick-slide:first-child) {
		display: flex;
	}
}

.container {
	/* stylelint-disable-next-line length-zero-no-unit */
	--thumb-height: 0px;

	position: relative;

	&.show_dots {
		--thumb-height: 25px;
	}

	&.show_dots.show_thumbs {
		--thumb-height: 65px;
	}

	:global(.slick-track) {
		align-items: stretch;
	}

	:global(.slick-slide) {
		display: none;
		align-items: stretch;
		height: auto;

		&:focus-within,
		&:focus {
			// filter: sepia(26) !important;
		}
	}

	:global(.slick-dots) {
		padding: 5px 0 0;
		list-style: none;
		margin-bottom: 0 !important;
	}

	:global(.slick-dots):not(:global(.slick-thumb)) {
		text-align: center;

		> li {
			display: inline-block;

			> button {
				width: clamp(5px, 2vw, 10px);
				height: clamp(5px, 2vw, 10px);
				margin: 0 5px;
				padding: 0;
				border: none;
				border-radius: 50%;
				font-size: 0;
				vertical-align: top;
				background-color: rgb(0 0 0 / 30%);
				cursor: pointer;
			}

			&:global(.slick-active) > button {
				background-color: rgb(0 0 0 / 80%);
			}
		}
	}

	:global(.slick-dots.slick-thumb) {
		display: flex !important;
		justify-content: stretch;

		> li {
			flex: 1 1 0;
			max-width: 20%;

			> button {
				width: 100%;
				max-height: var(--thumb-height);
				margin: 0;
				padding: 0;
				border: none;
				border-radius: none;
				overflow: hidden;
				vertical-align: top;
				background: transparent;
				cursor: pointer;
				opacity: 0.45;

				> img,
				> video {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			&:global(.slick-active) > button {
				opacity: 1;
			}
		}
	}

	.arrow_button {
		position: absolute;
		top: calc(50% - (0.5 * var(--thumb-height)));
		z-index: 2;
		width: clamp(25px, 10vw, 50px);
		height: clamp(25px, 10vw, 50px);
		border: none;
		border-radius: 50%;
		// background-color: rgb(0 0 0 / 40%);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		transform: translateY(-50%);
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: #ccc;
		}

		&.prev_arrow {
			left: 7px;
			background-image: url('https://eightsleep.imgix.net/Carousel_Arrow_left.png?v=1696483897');
		}

		&.next_arrow {
			right: 7px;
			background-image: url('https://eightsleep.imgix.net/Carousel_Arrow.png?v=1696482084');
		}
	}
}
