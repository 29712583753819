@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.outer {
	position: fixed;
	top: 0;

	width: 100%;
	padding: 2rem 4rem 0;

	z-index: 10;

	opacity: 0;
	//visibility: hidden;

	transform: translateY(-100%);

	$transition-time: 0.5s;
	$transition-easing: cubic-bezier(0.65, 0, 0.35, 1);

	transition: opacity $transition-time $transition-easing, visibility $transition-time $transition-easing, transform $transition-time $transition-easing;

	@include sm-down {
		padding: 0;
		top: auto;
		bottom: 0;

		transform: translate3d(0, 100%, 0);
	}
}

.outer_open {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	//visibility: visible;
}

.desktop_container {
	background-color: $white;
	color: $black;

	box-shadow: 0 339px 95px 0 rgba(0, 0, 0, 0), 0 217px 87px 0 rgba(0, 0, 0, 0.01), 0 122px 73px 0 rgba(0, 0, 0, 0.02), 0 54px 54px 0 rgba(0, 0, 0, 0.04), 0 14px 30px 0 rgba(0, 0, 0, 0.05);

	border-radius: 0.5rem;

	padding: 1rem 2rem;

	display: flex;

	justify-content: space-between;
	align-items: center;

	@include sm-down {
		display: none;
	}
}

.mobile_container {
	background-color: $white;
	color: $black;

	padding: 1rem 1.25rem;
	border-top: 1px solid #dddcdb;

	@include md-up {
		display: none;
	}
}

.product_title {
	@include headline9;
	margin-bottom: 0.25rem;
}

.benefits_list {
	list-style: none;
	overflow: auto hidden;

	display: flex;
	gap: 0.5rem;
	margin: 0;
}

.benefit {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 0.25rem;

	p {
		@include headline10;
		color: #999;
		font-size: 0.625rem; // TODO Out of spec

		white-space: nowrap;
	}
}

.desktop_price_container {
	display: flex;
	align-items: center;

	.pricing {
		// display: flex;
		align-items: center;
		margin: 0 2rem;
		flex-direction: column;

		span {
			display: block;
			text-align: right;
		}
	}
}

.price {
	@include headline9;
	font-size: 1rem; // TODO out of spec
}

.strike_price {
	@include headline9;
	font-size: 1rem; // TODO out of spec
	color: #adadad;
	text-decoration: line-through;
}

.or {
	@include body6;
	color: #adadad;
}

.mobile_top_container {
	padding-bottom: 1rem;
	border-bottom: #dddcdb 1px solid;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mobile_pricing_info {
	display: flex;
	gap: 0.5rem;
	align-items: center;

	margin: 0;

	flex-wrap: wrap;
}

.mobile_bottom_container {
	display: flex;
	min-width: 0;

	align-items: center;
	max-width: 100%;
	overflow: auto;
	height: 1.3rem;
	scrollbar-width: none;
	position: relative;
	margin-top: 0.7rem;

	&::-webkit-scrollbar {
		display: none;
	}

	svg {
		max-height: 1rem;
		margin-right: 0.25rem;
	}

	.slides {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.button_hider {
		width: 47px;
		z-index: 3;
		position: relative;
		height: 16px;
		background-color: white;
	}
}

.button {
	position: absolute;
	right: 1.25em;
	bottom: 16px;
	width: 60px;
	height: 18px;
	border: none;
	padding: 0 0 0 40px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 5.39%, #fff 80%);
	z-index: 2;

	svg {
		width: 16px;
		margin: 0;
	}
}

.mobile_alternate_pricing {
	display: flex;
	flex: 1;

	align-items: center;

	svg {
		width: auto;
		height: 0.75rem;
		margin-left: 0.3rem;
	}

	p {
		@include headline10;
		font-size: 0.75rem; // TODO out of spec
		color: #999;
		white-space: nowrap;
	}
}

.alternate_pricing {
	@include tag1;

	color: #a0a0a0;

	svg {
		width: auto;
		height: 1em;
		margin: 0 0.2ch;
	}

	@include md-up {
		margin-bottom: 0;
		margin-top: 5px;
	}

	@include sm-down {
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	:global(svg.affirmTransform) {
		margin-top: -2px;
	}
}

.alternate_pricing_inner {
	@include tag1;
}

.simple_sticky {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	background-color: $white;
	color: $black;
	padding: 20px 30px;
	border-radius: 8px;
	box-shadow: 0px 339px 95px 0px rgba(0, 0, 0, 0), 0px 217px 87px 0px rgba(0, 0, 0, 0.01), 0px 122px 73px 0px rgba(0, 0, 0, 0.02), 0px 54px 54px 0px rgba(0, 0, 0, 0.04),
		0px 14px 30px 0px rgba(0, 0, 0, 0.05);

	.pricing {
		display: flex;
		align-items: center;
		gap: 10px;

		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);

		@include xs-only {
			position: relative;
			transform: none;
			top: unset;
			right: unset;
		}
	}

	.simple_sticky_content {
		display: block;
		position: relative;
	}

	.product_info {
		display: flex;
		flex-direction: column;
		margin-bottom: 3px;
	}

	.prices {
		display: flex;
		gap: 8px;

		> del {
			color: $text-grey-dark;
		}

		@include xs-only {
			gap: 5px;
		}
	}

	.benefit > p {
		font-size: 11px;
	}
}
