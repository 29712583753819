@import 'styles/mixins/media-queries';

.video_wrapper {
	display: block;
	width: 100%;
	height: 100%;
}

.video_mute {
	position: absolute !important;
	bottom: 1.5rem;
	right: 1.5rem;

	@include sm-down {
		bottom: 1rem;
		right: 1rem;
	}
}

.slide_offset_desktop > img {
	@include md-up {
		padding-bottom: 70px;
		background-color: #000;
	}
}

picture.sleep_elixir_product.sleep_elixir_product > img {
	object-position: center;
	padding: 3vh 0;

	@include sm-up {
		padding: 11vh 0;
	}
}

picture.sleep_elixir_nutritional_info.sleep_elixir_nutritional_info > img {
	object-position: center;
}
