@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.nav_button {
	background: transparent;
	border: none;
	width: 50%;
	height: calc(100% - 90px);
	padding: 0;
	position: absolute;
	top: 0;
	z-index: 1;
	bottom: 0;
	cursor: default;

	@include sm-down {
		height: 100%;
	}

	&.prev_slide {
		left: 0;
	}

	&.next_slide {
		right: 0;
	}
}
