@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 0.5rem;

	&[data-has-badge='true'] {
		margin-top: 0.25rem;
	}
}
