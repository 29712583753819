@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	color: $black;

	list-style: none;
	padding: 0;
	margin: 0;

	display: grid;
	grid-template-columns: 1fr;

	padding-bottom: $spacing-xxx-large-desktop;

	@include sm-down {
		padding-bottom: $spacing-xxx-large-mobile;
	}
}

.container > li {
	&:not(:last-child) {
		margin-bottom: 5rem;
	}
}

.container_shop_scroll > li {
	@include md-up {
		&:not(:last-child) {
			margin-bottom: 4rem !important;
		}
	}
}

.selector_container {
	color: $black;

	position: relative;
}

.error_box {
	margin: 1rem 0;

	display: flex;
	align-items: center;
	gap: 0.25rem;

	@include xs-only {
		justify-content: center;
	}

	p {
		color: #df1c2f;
	}
}

.selection_title {
	margin-bottom: 0.5rem;
}

.selection_subtitle {
	position: relative;
	color: $text-grey-dark;

	min-height: 1.45em;

	margin: 0.25rem 0;

	p,
	a,
	button,
	:global(.truemed-instructions-open),
	span {
		font: inherit !important;
	}

	svg {
		height: 1em;
		width: auto;
	}

	:global(.truemed-instructions-open),
	a,
	button {
		text-decoration: underline;
		color: $text-grey-dark;
		cursor: pointer;
	}

	button {
		background: none;
		border: none;
		padding: 0;
		margin: 0;
	}
}

.selection_header {
	margin-bottom: 1.25rem;
}

.container fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.modal_buttons {
	display: flex;
	gap: 1rem;

	margin: 1.25rem 0;

	justify-content: center;
	align-items: center;

	list-style: none;
	padding: 0;
}

.mobile_visual {
	@include md-up {
		display: none;
	}

	margin-bottom: 30px;
}

.fragile_logo {
	height: 1em;

	transform: translateY(0.1em);
}
