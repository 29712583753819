@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.pricing_info {
	display: flex;
	gap: 0.5rem;
	align-items: center;

	flex-wrap: wrap;

	del {
		color: #999;
	}
}
