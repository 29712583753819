@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	width: 100%;
}

.option {
	box-shadow: transparentize($black, 0.8) 0 0 0 1px inset;
	cursor: pointer;
	background-color: $white;
	color: $black;
	padding: 1.5rem;
	border-radius: 0.5rem;

	display: grid;
	grid-template-columns: 1.5rem 1fr;
	gap: 0.5rem;

	align-items: start;

	&[data-selected='true'] {
		box-shadow: rgb(0, 56, 255) 0px 0px 0px 2px inset;
	}

	transition: all 0.2s ease-out;
}

.checkbox {
	margin-top: 0.375rem;
	pointer-events: none;
}

.icon_list {
	list-style: none;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;

	margin: 1rem 0;
}

.icon_row {
	display: grid;
	grid-template-columns: 1rem 1fr;

	align-items: center;
	gap: 0.5rem;

	p {
		color: $text-grey-dark;
	}
}

.title {
	display: block;
}

.description {
	display: block;

	color: $text-grey-dark;
}

.modal_trigger {
	color: $text-grey-dark;

	text-decoration: underline;
	cursor: pointer;

	margin-top: 0.5rem;
}

.shipping_text {
	color: #636363;
}
